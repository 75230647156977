import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, input, Input, Signal, ViewChild } from "@angular/core";
import { AbstractMatFormFieldControl, comparing, controlValues, createTextMatcher, groupBy, mapNotNull } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, combineLatest, from, merge, Observable } from "rxjs";
import { distinctUntilChanged, first, map, shareReplay, skip, takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { CompanyEndpoint, CompanyId, OfficeId, OfficeInfo } from "apina-frontend";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: "app-select-customer-office",
    templateUrl: "./select-customer-office.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MatFormFieldControl, useExisting: SelectCustomerOfficeComponent },
    ],
    imports: [
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    host: {
        '(focus)': 'focus()'
    }
})
export class SelectCustomerOfficeComponent extends AbstractMatFormFieldControl<OfficeId> implements AfterViewInit {

    private static nextId = 0;

    readonly control = new FormControl<string | unknown>("", {nonNullable: true});
    readonly filteredOfficesByCompany: Signal<readonly OfficeResultGroup[] | undefined>;

    private readonly selectedOfficeId = new BehaviorSubject<OfficeId | null>(null);

    private readonly offices$: Observable<ReadonlyArray<OfficeInfo>>;

    @ViewChild('inputField') inputField!: ElementRef;

    @ViewChild('officeAutoComplete') officeAutoComplete?: MatAutocomplete;

    hint = input<string | null | undefined>(undefined);

    private readonly _companyId = new BehaviorSubject<CompanyId | null>(null);
    private readonly _defaultOfficeIds = new BehaviorSubject<OfficeId[]>([]);

    @Input()
    set defaultOfficeIds(ids: OfficeId[] | null) {
        this._defaultOfficeIds.next(ids ?? []);
    }

    @Input()
    set companyId(id: CompanyId | null) {
        this._companyId.next(id);
    }

    /**
     * Is the autocomplete currently open?
     *
     * We'd like to use officeAutoComplete.isOpen, but it still reports "open" when closing events arrive,
     * so we need to track state manually.
     */
    private _autoCompleteOpen = false;

    get value(): OfficeId | null {
        return this.selectedOfficeId.value;
    }

    set value(id: OfficeId | null) {
        if (id !== this.selectedOfficeId.value) {
            this.selectedOfficeId.next(id);
            this.updateOfficeName();
            this.stateChanges.next();
        }
    }

    constructor(companyEndpoint: CompanyEndpoint) {
        super("app-select-customer-office", SelectCustomerOfficeComponent.nextId++);

        this.offices$ = from(companyEndpoint.findAllOffices()).pipe(shareReplay(1));

        const availableOffices$ = combineLatest([this._companyId, this.offices$]).pipe(
            map(([companyId, offices]) => (companyId == null) ? offices : offices.filter(it => it.companyId === companyId)));

        this.filteredOfficesByCompany = toSignal(combineLatest([controlValues(this.control), availableOffices$, this._companyId, this._defaultOfficeIds]).pipe(
            map(([v, offices, companyId, defaultOfficeIds]) => {
                if (typeof v !== "string" || v === "") {
                    const defaultOffices = (companyId != null)
                        ? offices.filter(it => it.companyId === companyId)
                        : mapNotNull(defaultOfficeIds, id => offices.find(it => it.id === id));

                    return this.createGroups(defaultOffices);
                }

                const matcher = createTextMatcher(v);
                return this.createGroups(offices.filter(it => matcher([it.name, it.companyName, it.alias])));
            })));

        this.stateChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            if (!this.focused)
                this.updateOfficeName();
        });
    }

    private createGroups(filteredOffices: OfficeInfo[]): OfficeResultGroup[] {
        const result = groupBy(filteredOffices, it => it.companyName);

        // Assumes that company names are distinct, but it the assumption fails, nothing bad happens:
        // the offices just end up in same group.
        return Object.entries(result).map(([companyName, filteredCompanyOffices]) =>
            new OfficeResultGroup(companyName, filteredCompanyOffices, !filteredCompanyOffices[0].onlyOffice)
        ).sort(comparing(it => it.name));
    }

    ngAfterViewInit(): void {
        merge(this.officeAutoComplete!.opened.pipe(map(() => true)), this.officeAutoComplete!.closed.pipe(map(() => false))).pipe(takeUntil(this.componentDestroyed$)).subscribe(open => {
            this._autoCompleteOpen = open;
            this.stateChanges.next();
        });
    }

    protected override get hasCustomFocus(): boolean {
        return this._autoCompleteOpen;
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    focus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.focus();
    }

    fieldReceivedFocus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.select();
    }

    selectOffice(office: OfficeInfo | null): void {
        if (office != null) {
            this.value = office.id;
            this.control.setValue(office.fullDisplayName);
        } else {
            this.value = null;
            this.control.setValue("");
        }
    }

    private updateOfficeName(): void {
        const id = this.value;
        if (id != null) {
            this.findOffice(id).pipe(first()).subscribe(v => this.control.setValue(v?.fullDisplayName ?? ""));
        } else {
            this.control.setValue("");
        }
    }

    private findOffice(id: OfficeId): Observable<OfficeInfo | undefined> {
        return this.offices$.pipe(map(items => items.find(it => it.id === id)));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        // BehaviorSubject broadcasts the initial value, we skip it to avoid sending invalid change event
        this.selectedOfficeId.pipe(skip(1), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(fn);
    }
}

class OfficeResultGroup {

    constructor(readonly name: string, readonly offices: ReadonlyArray<OfficeInfo>, readonly showAsGroup: boolean) {
    }
}
